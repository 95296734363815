import React from 'react'
import '../styles/productDetails.css'
import Helmet from 'react-helmet'

import img2 from '../images/Rect8.png'
import logo from '../images/headerLogo.png'
import Layout from '../components/layout'
import square from '../images/square.png'

import rectItems1 from '../images/medical/Rectangle 34.png'
import rectItems2 from '../images/medical/Rectangle 31.png'

import product1 from '../images/medical/image 15.png'
import product2 from '../images/medical/image 16.png'
import product3 from '../images/medical/image 17.png'
import product4 from '../images/medical/image 19.png'
import product5 from '../images/medical/image 20.png'
import product6 from '../images/medical/image 21.png'
import product7 from '../images/medical/image 22.png'
import product8 from '../images/medical/image 23.png'
import Contact from '../components/contact'




const Product4 = () => {

  return (

    
    <div className=' font-hind'>
      <Layout>
      <Helmet title="Medical Refrigeration Systems | Better Freezing Solutions"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Complete solution for refrigeration systems.We provide supermarket refrigeration,cold storage refrigeration,kitchen refrigeration medical refrigeration,freezers,coolers.',
                    }
                ]}
        />


        <div className='productDetailSection'>
          
          <div className='flex  relative'>
            <img className='img2 absolute right-0' src={img2} alt='' />

            <div className='PDItemsSection absolute right-0 z-30 flex sm:justify-end justify-center'>
              <div className='flex items-end'>
                <img className='PDImage1 absolute z-10' src={rectItems1} alt='item' />
                <img className='PDImage2' src={rectItems2} alt='item' />
              </div>
            </div>
          </div>

          
          
          <div className='productTitleSection flex justify-center text-white'>
            <div className='z-30'>
              
              <div className='flex justify-center items-center  sm:items-end sm:justify-start px-6'>
                <img className='logo ' src={logo} alt='' />
                <h1 className='productTitle hidden sm:flex text-white'>Medical Refrigeration</h1>
                <h1 className='productTitle block sm:hidden text-white'>Medical<br/>Refrigeration</h1>
              </div>
              
              <div className=''>
                <div className='PDListGrid grid lg:grid-cols-3  sm:grid-cols-2 grid-cols-1 xl:gap-8 xl:gap-y-2 lg:gap-5 lg:gap-y-2  md:gap-0 md:gap-y-1'>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image1' /><h1 className='PDListText'>Mortuary Chambers </h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image2' /><h1 className='PDListText'>Vaccine Transporter</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image3' /><h1 className='PDListText'>Ice Lined Refrigerator</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image4' /><h1 className='PDListText'>Pharmacy Refrigerator</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image5' /><h1 className='PDListText'>Modular Cold Room</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image6' /><h1 className='PDListText'>Medical Freezer</h1> </div>
                </div>
              </div>

              
            </div>
          </div>

        </div>

        <div className='flex justify-center'>
          <h1 className='PDText'>
          Blue Star range of ultra-low upright freezers comes with highly efficient    compressors and eco-friendly 
          refrigerants. These medical freezers offers a wide variety of research and storage applications such as low 
          temperature scientific experiments, preservation of plasma, biomaterials, vaccines and biomedical properties
           of military products. It is suitable for research institutes, electronic industries, chemical engineering 
           industries, hospitals, university laboratories, military industries, sanitation and antiepidemic         
           stations.
              
          </h1>
        </div>

        <div className='flex justify-center'>
          <div className='PDProductGrid grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  2xl:gap-10 gap-3 gap-y-5 pb-4'>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product1} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product2} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product3} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product4} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product5} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product6} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product7} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product8} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
          </div>
        </div>
        <Contact/>
      </Layout>
    </div>
  )
}

export default Product4
